var render, staticRenderFns
import script from "./card-header.vue?vue&type=script&lang=js"
export * from "./card-header.vue?vue&type=script&lang=js"
import style0 from "./card-header.vue?vue&type=style&index=0&id=141bbcf7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141bbcf7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('141bbcf7')) {
      api.createRecord('141bbcf7', component.options)
    } else {
      api.reload('141bbcf7', component.options)
    }
    
  }
}
component.options.__file = "src/views/home/components/card-header.vue"
export default component.exports