<!--
 * @Author: wuqi
 * @Date: 2020-11-24 15:04:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-27 15:02:04
 * @Description:
-->
<script>
export default {
  name: "CardHeader",
  props: {
    title: {
      type: String,
      default: ""
    },
    edit: {
      type: Boolean,
      default: false
    },
    num: {
      type: String,
      default: ""
    },
    menuData: {
      type: Object
    }

  },
  data() {
    return {
        defaultHeaderStyle: {
          name: "111",
          showName: false,
          titleText: {
              fontSize: "14px",
              isWeight: false,
              alignment: "iconzuoduiqi1",
              color: "#000"
          },
          iconColor: "#f00",
          titleStyle: 1,
          chartStyle: 7,
          chartColorGrou: "1",
          templateText: {
              fontSize: "14px",
              isWeight: false,
              color: "#fff"
          },
          statisticsNum: false,
          statisticsText: {
              fontSize: "14px",
              isWeight: false,
              color: "#fff"
          },
          alignment: "1",
          textSorting: "1",
          textAlignment: "left",
          cartBg: {
              style: "1",
              color: "#fff"
          },
          borderStyle: {
              style: "0",
              size: "1px",
              color: "#fff"
          },
          bgStyle: "#fff"
        },
        headerStyle: {}
    };
  },
  created() {
    // console.log(this.menuData, "this.menuData进入了几次");
    this.getHeaderStyleData();
  },
  watch: {
    menuData(newValue) {
      this.getHeaderStyleData();
      // console.log(newValue, "card-headers");
    }
  },
  mounted() {

  },
  methods: {
    getHeaderStyleData() {
      if (this.menuData?.headerStyle) {
        this.headerStyle = JSON.parse(this.menuData.headerStyle);
      } else {
        this.headerStyle = this.defaultHeaderStyle;
        // this.headerStyle.name = this.menuData.name;
      }
    },
    showIcon() {
      return this.menuData ? <en-icon
                                name={this.menuData.customIcon}
                                size="12px"
                                class='customIcon'
                                style={{ color: this.headerStyle.iconColor }}
                            ></en-icon> : "";
    },
    nameLayout() {
        // console.log(this.headerStyle, "渲染字段");
        if (!this.headerStyle) {
          const num = <span class="num">{this.num}</span >;
          return (
            <div class="card-define-title">
                {this.showIcon()}
                {this.title}
                {this.num ? num : ""}
            </div>
          );
        }

        let styleStr = "";
        styleStr += `color:${ this.headerStyle.titleText.color};`;
        styleStr += `font-size:${ this.headerStyle.titleText.fontSize};`;
        styleStr += `font-weight:${ this.headerStyle.titleText.isWeight ? "800" : "400"};`;
        // console.log(this.headerStyle.titleText,'this.headerStyle.titleText')
        switch (this.headerStyle.titleText.alignment) {
            case "iconzuoduiqi1":
                styleStr += "justify-content:flex-start;";
                break;
            case "juzhongduiqi1":
                styleStr += "justify-content:center;";
                break;
            case "iconyouduiqi1":
                styleStr += "justify-content:flex-end;";
                break;
            default:
                styleStr += "justify-content:flex-start;";
                break;
        }
        // console.log(this.menuData, "this.menuData");
        // this.headerStyle.titleStyle =
        const num = <span class="num">{this.num}</span >;
        let str = "";

        switch (this.headerStyle.titleStyle) {
            case 1:
            case 3:
                // styleStr += "background:#fff";
                str = (
                    <div class="card-define-title" style={styleStr}>

                        {this.title}
                        {this.num ? num : ""}
                    </div>
                );
                break;
            case 2:
            case 4:
                // styleStr += "background:#fff";
                str = (
                    <div class="card-define-title" style={styleStr}>
                        {this.showIcon()}
                        {this.title}
                        {this.num ? num : ""}
                    </div>
                );
                break;

            default:
                // styleStr += "background:#fff";
                str = (
                    <div class="card-define-title" style={styleStr}>

                        {this.title}
                        {this.num ? num : ""}
                    </div>
                );
                break;
        }

        return str;
    },
    editData() {
      this.$emit("editData");
    },
    editTheme() {
        // console.log("cccccccc");
      this.$emit("editTheme");
    },
    deleteData() {
        // console.log("ccc");
      this.$emit("deleteData");
    },
    chart13style() {
        // 指标卡样式 按钮点击事件
      this.$emit("chart13style");
    },
    headRighEdit() {
        return (
            <span>
                <span
                    onClick={() => this.editTheme()}
                >
                    <en-icon
                        class='delete-icon editIcon pifu-icon'
                        name="iconzhuti"
                        size="15px"
                        onClick={() => this.editTheme()}
                      ></en-icon>
                </span>
                <span
                    onClick={() => this.editData()}
                >
                    <en-icon
                        class='delete-icon editIcon edit-icon'
                        name="iconbianji1"
                        size="15px"
                        onClick={() => this.editData()}
                      ></en-icon>
                </span>
                <span
                    onClick={() => this.deleteData()}
                >
                    <en-icon
                      class='delete-icon editIcon'
                      name="shanchu-danchuang"
                      size="15px"
                    ></en-icon>
                </span>
            </span>
        );
    },
    cardHeadStyle() {
        let str = null;
        switch (this.headerStyle.titleStyle) {
            case 1:
            case 2:
                str = "background:#fff";
                break;
            case 3:
            case 4:
                str = null;
                break;
            default:
                str = "background:#fff";
                break;
        }
        return str;
    }
  },
  render() {
    return (
      <div class="card-define-head" style={this.cardHeadStyle()}>
        <slot name='left' >
            { this.nameLayout() }
        </slot>
        <div class='right' >
            {
              this.edit ? this.headRighEdit() : ""
            }
          <slot name='right' class='right-icon-wrap'></slot>
        </div>
      </div>
    );
  }
};
</script>

<style  scoped>
  .card-define-head {
    width: 100%;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    padding:5px 15px;
    position: relative;
    /* border-bottom: 1px solid #f3f3f3; */
  }
  .card-define-head >>> .card-define-title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    height: 40px;
    width: 85%;
    display: flex;
    align-items: center;

  }
  /* .card-define-title .num{
    padding: 3px 6px;
    background: #F54646;
    border-radius: 16px;
    font-size: 10px;
    min-width: 26px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    color: #FFFFFF;
    font-family: Microsoft YaHei;
    font-weight: 400;
  } */
  >>> .num{
    padding: 3px 6px;
    background: #F54646;
    border-radius: 16px;
    font-size: 10px;
    min-width: 26px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    color: #FFFFFF;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  /* .card-define-title >>> .num{
    padding: 3px 6px;
    background: #F54646;
    border-radius: 16px;
    font-size: 10px;
    min-width: 26px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    color: #FFFFFF;
    font-family: Microsoft YaHei;
    font-weight: 400;
  } */
  .card-define-head .editIcon {
      cursor: pointer;
      color: #a9b5c6;
      margin-left: 10px;
    }
  .card-define-head .customIcon {
    cursor: pointer;
    color: #a9b5c6;
    margin-right: 10px;
  }
  .card-define-head  .right{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    .pifu-icon{
        color: #9367EB!important;
    }
    .edit-icon{
        color: #26C393!important;
    }
</style>
